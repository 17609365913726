.skills-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    // background-color: red;
    align-content: space-around;
  
    margin: 4rem 0 2rem;
    margin-top: 0.5rem;
    padding-bottom: -2rem;
  
    .app__work-filter-item {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      background-color: #fff;
      color: #000;
      font-weight: 800;
  
      cursor: pointer;
      transition: all 0.3s ease;
      margin: 0.5rem;
  
      &:hover {
        background-color: var(--secondary-color);
        color: #fff;
      }
  
      @media screen and (min-width: 2000px) {
        padding: 1rem 2rem;
        border-radius: 0.85rem;
      }
    }

    .skill-active {
        background-color: var(--secondary-color);
        color: #fff;
      }
    }
  
.skills-circles {

        flex: 0.75;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        height: 100%;
        align-content: space-evenly;
        // margin-top: -5rem;
        // display: grid;
        // justify-content: space-evenly;
        // align-content: space-evenly;
        // grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));
        // grid-template-columns: repeat(auto-fit, 100px);
        

        // background-color: yellow;
      
        .skills-bubble {
            
          width: 6.5rem;
          height: 6.5rem;
          border-radius: 50%;
          margin: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
      
          background: var(--white-color);
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

          @media screen and (max-width: 600px) {
            width: 5rem;
          height: 5rem;
            // padding: 1rem 2rem;
            // border-radius: 0.85rem;
          }
      
          img {
            width: 60%;
            height: 60%;
          }
          .hover-text {
            position: absolute;
            padding: 5px;
            background-color: rgb(219, 217, 166);
            color: rgb(0, 0, 0);
            font-size: 12px;
            border-radius: 4px;
  
            // opacity: 1;
            visibility: hidden;
            // transition: opacity 0.3s ease-in-out;
            // z-index: 5;
          }
          
          &:hover {
            .hover-text {
              visibility: visible;
              cursor: default;
            }
          }
          
          

    }
}

.skills-head-text {
    font-size: 2.4rem;
    font-weight: bold;
    color: var(--secondary-color);
    text-shadow: .5px .5px 1px rgba(0, 0, 0, 1);
    margin-bottom: 1rem;

    // @media screen and (max-width: 768px) {
    //   font-size: 1.8rem;
    // }
}





