

.modal_main {


    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}


@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);

.modal_main-images {

    display: flex;
    // flex-direction: row;
    justify-content: center;
    // align-items: center;
    // background-color: blue;


    @media screen and (max-width: 768px) {

        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 30px;
    

      }

    

    .modal_main-images-image {

        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;
        // background-color: red;

        img {
            width: 95%;
            height: 1000%;
            border-radius: 15px;
            object-fit: contain;
            margin-bottom: 1rem;
          }

          p {
            font-weight: bold;
            text-align: center;
            
          }

    }

}

.modal_main-description {

    margin: 1rem;
    margin-top: 2rem;
    font-family: Poppins, sans-serif;
    font-size: 1.25rem;

    @media screen and (max-width: 768px) {

      font-size: 1rem;


    }

}




.modal-about-main {
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  // THIS WAS CAUSING THE PROBLEM
  gap: 1;
  line-height: 1.5;
	font-family: "Outfit", sans-serif;
	color: #2d232e;
  overflow: auto;
  align-items: center;

}

.modal-about-main-title {

  text-align: center;
  margin-top: 10px;
  color: rgb(50, 123, 240);
  margin-bottom: .5rem
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
.modal-about-main-description {
  font-family: Poppins, sans-serif;

  // margin: 1rem;
  // padding-top: 0.5rem;
  // background-color: blue;
  width: 90%;
  padding-bottom: 1rem;
  @media screen and (max-width: 768px) {
    font-size: .6rem;
  }

}

.modal-about-main-description-education {
  display: flex;
  // background-color: red;
  width: 100%;
  justify-content: space-evenly;
    font-family: Poppins, sans-serif;



  .degree {
    @media screen and (max-width: 768px) {
      font-size: .8rem;
    }
    h2 {
      margin-bottom: .1rem;
      text-align: center;
    }
  }
}

.modal-about-main-description-h2 {

  color: blue;
  
}






.modal-about-main-image {

  display: flex;
  justify-content: center;

  // background-color: red;
  // display: flex;
  // justify-content: center;

  // img {
  //   height: 50%;
  //   object-fit: fit;
  // }
  
}

.modal-about-main img {
  max-width: 60%;
  // max-height: 50%;
  margin: 1rem;
  border-radius: 15px;
}

@import url('https://fonts.googleapis.com/css?family=Special+Elite');
.test {
	font-weight: 100;
	font-size: 1.5rem;
	max-width: 80%;
	line-height: 1.4;
	position: relative;
	margin: 0;
	padding: .5rem;
  font-family: 'Special Elite', cursive;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

