#home {
    position: relative;
    background: url("../../assets/bgIMG.png");
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
  
    // disable the default padding styles
    .app__wrapper {
      padding: 0;
  
      .copyright {
        display: none;
      }
    }
  }

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    padding: 3.5rem 2rem 0rem;
    // \\\background-color: red;


    @media screen and (min-width: 2000px) {
        padding-top: 6rem;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        padding: 6rem 2rem 0rem;
    }

    @media screen and (max-width: 450px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    // align-items: center;
    height: 100%;
    position: relative;
    top: 1rem;
    margin: 0 2.5rem;
    // background-color: red;


    @media screen and (max-width: 1200px) {
        width: 100%;
        margin-right: 0rem;
        top: 0rem;
    }
}

.app__header-badge {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background-color: blue;
    flex: 0.65;
    gap: 0rem;

    height: 100%;
    margin-right: 0.5rem;
    // position: relative;
    // top: 1rem;
    @media screen and (min-width: 1400px) {
      gap: 3rem;
    }

    @media screen and (max-width: 1200px) {
      // width: 100%;
      // margin-right: 0rem;
      // top: 0rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // background-color: blue;
      display: block;
      margin-right: -0.2rem;
      gap: 0rem;
  }

    .badge-tag {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
    }
  
    .badge-cmp,
    .tag-cmp {
      padding: 1rem 2rem;
      background: var(--white-color);
      border-radius: 15px;
      flex-direction: row;
      width: auto;
      // background-color: yellow;
  
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }

    .badge-cmp {
        // flex-direction: column;
        // margin-top: 1rem;
      // background-color: yellow;
    
        p {
        //   background-color: yellow;
          width: 100%;
          text-transform: uppercase;
          text-align: center;
        }
      }
  
    .tag-cmp {
      flex-direction: column;
      margin-top: 1rem;
      // background-color: red;
  
      p {
        // background-color: yellow;
        width: 100%;
        text-transform: uppercase;
        text-align: center;
      }
    }

  
    span {
      font-size: 2.5rem;
    //   background-color: red;
  
      @media screen and (min-width: 2000px) {
        font-size: 5rem;
      }
    }
  
    @media screen and (max-width: 1200px) {
      justify-content: flex-start;
      align-items: flex-start;
    }


    .app__header-misha {

      // flex: 0.5;
      height: auto;
  
      display: flex;
      // justify-content: flex-end;
      // align-items: flex-end;
      //position: relative;
      // background-color: red;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;

      img {

        width: 65%;
        //height: 82%;
        //object-fit: fill;
        z-index: 1;
        border-radius: 50%;
        padding-top: 1rem;
      }

      .tag-misha {

        padding: .4rem .8rem;
        background: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;
        margin-top: 1rem;
        
    
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

      }

      .overlay_circle {
        position: absolute;
        left: 10;
        right: 0;
        bottom: 0;
        z-index: 0;
        padding-top: 50rem;
        width: 100%;
        height: 100%;
    }


      @media screen and (max-width: 1200px) {
        display: none;
      }

    }





  }

  .app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    margin-left: 1rem;
    // background-color: yellow;
  
    div {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin: 1rem;
  
      background: var(--white-color);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  
      img {
        width: 60%;
        height: 60%;
      }
    }
    // div:nth-child(1) {

    //     width: 100px;
    //     height: 100px;
    // }

    // div:nth-child(2) {
    //     margin: 1.75rem;
    //     width: 150px;
    //     height: 150px;
    // }

    // div:nth-child(3) {
    //     width: 70px;
    //     height: 70px;
    // }

    @media screen and (min-width: 2000px) {

        div:nth-child(1) {
            width: 400px;
            height: 400px;
        }
    
        div:nth-child(2) {
            width: 170px;
            height: 170px;
        }
    
        div:nth-child(3) {
            width: 200px;
            height: 200px;
        }
      }

      @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div {
            margin: 1rem;
        }
      }
}

.app__header-img {
    flex: 1;
    height: auto;

    display: flex;
    // justify-content: flex-end;
    // align-items: flex-end;
    position: relative;
    // background-color: green;
    justify-content: space-evenly;
    align-items: center;

    img {
        width: 82%;
        // height: 80%;
        object-fit: contain;
        z-index: 1;
    }

    .overlay_circle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 90%;
    }

    @media screen and (max-width: 1200px) {
        margin: 2rem 0;
      }
}