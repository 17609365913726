
// .app__work {
//     flex: 1;
//     width: 100%;
//     flex-direction: column;
//     // background-color: red;
//     // margin-top: -1rem;

// }

.portfolio-main {
  width: 100%;
  flex-direction: column;
  // background-color: red;
  // justify-content: space-evenly;
  // align-items: center;

}


.portfolio-header {

  text-align: center;
  // margin-top: -.2rem;
  margin-bottom: 2rem;

  h2 {

      font-size: 3rem;
      font-weight: bold;
      color: #f99100;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
      margin-bottom: 0.1rem;
  }

  p {

      font-size: 1.5rem;
      font-weight: bold;
      color: black;
      text-shadow: .1px .1px .2px rgba(0, 0, 0, 1);
      text-align: center;
  }

  .ood-message {

    font-size: .9rem;
    margin-top: 0.5rem;



  }
}


.app__projects {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    // margin-top: -2rem;
    // background-color: green;

    .app__project-item {
        flex-direction: column;
        width: 325px;
        height: 300px;
        // margin: 0.5rem;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        @media screen and (min-width: 1500px) {
          width: 500px;
          height: 500px;
      }

        .image-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
          
          }
    }
}



.project-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  // color: #fff;
  margin-top: 2.5rem;
  // background-color: yellow;
  cursor: pointer;

}
  
  
.app__project-item .project-info .project-title {
  font-size: 25px;
  font-weight: bold;
  color: #fcbc1d;;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
  text-align: center;
}

.app__project-item .project-info .p-text {
  font-size: 15px;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
  text-align: center;
  margin-top: 3rem;
}
  
.image-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    // border-radius: 15px;
  }

  .image-wrapper:hover::before {
    opacity: 1;
    background-color: rgba(3, 30, 150, 0.8);
  }



.project-modal {
  position: fixed;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  border: 1px solid black;
  border-radius: 10px;
  z-index: 10;


  max-height: 80vh; //THIS IS IMPORTANT
  overflow-y: auto; // for scroll bars
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  background-color: rgb(230, 240, 242);
  width:90%;

  // max-height: 80%;
  // margin-top: 1rem;
  // overflow: auto;
  // justify-content: space-evenly;
  // align-items: center;


  .project-modal-summary {
    text-align: center;
    // width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem;
    margin-top: 0rem;

    }


}

.project-modal-header {
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: red;
  text-align: center;
  

  .project-modal-header-title {
      font-size: 2.4rem;
      font-weight: bold;
      color: #f99100;
      text-shadow: .5px .5px 1px rgba(0, 0, 0, 1);
      margin: 0.3rem;
      text-align: center;
      width: 100%;
      margin-top: 0.8rem;


  }

}

.close-button {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  font-size: 2.5rem;
  font-weight: bold;
  position: absolute;
  right: 5px;
  top:-6px;
}

.project-modal-body {
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: 0.5rem;
}

#overlay {
  position: fixed;
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);

}



.button-close-project {
  margin-top: 1.3rem;
  background-color: #111827;
  border: 1px solid transparent;
  border-radius: .75rem;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex: 0 0 auto;
  font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: .75rem 1.2rem;
  text-align: center;
  text-decoration: none #6B7280 solid;
  text-decoration-thickness: auto;
  transition-duration: .2s;
  transition-property: background-color,border-color,color,fill,stroke;
  transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
}

.button-40:hover {
  background-color: #374151;
}

.button-40:focus {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 768px) {
  .button-40 {
    padding: .75rem 1.5rem;
  }
}