#papers {

    background-color: #388ebc;


}


.papers-main {
    flex: 1;
    width: 100%;
    flex-direction: column;
    // background-color: rgb(156, 212, 244);
    background-color: #388ebc;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.papers-title {

    text-align: center;
    margin-bottom: 2.5rem;

    h2 {

        font-size: 3rem;
        font-weight: bold;
        color: #f99100;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
        margin-bottom: 0.6rem;

    @media screen and (max-width: 768px) {
      font-size: 2.2rem;
    }
    }

    p {

        font-size: 1.5rem;
        font-weight: bold;
        color: white;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
        text-align: center;
        @media screen and (max-width: 768px) {
          font-size: 1.1rem;
        }

    }


}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
.papers-body {


    display: flex;
    width: 100%;
    flex-direction: column;
    // background-color: red;
    // justify-content: space-between;
    // align-items: center;
    // padding: 2rem;
    font-family: Poppins, sans-serif;


}


.papers-body-publications {
    

    display: flex;
    width: 100%;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
    // background-color: red;
    // padding: 2rem;

    h2 {

        font-size: 2.4rem;
        font-weight: bold;
        color: #f99100;
        text-shadow: .5px .5px 1px rgba(0, 0, 0, 1);
        margin-bottom: 1rem;

        @media screen and (max-width: 768px) {
          font-size: 1.8rem;
        }
    }

}

.publication-main {
    padding: 1rem;
    // margin-left: -2rem;
    // width: 80vw;
    font-family: Poppins, sans-serif;
    width: 94%;


    .publication-main-title {
        // display: block;
        font-size: 1.3rem;
        font-weight: bold;
        color: #edbc48;
        text-shadow: .1px .1px .2px rgba(0, 0, 0, 1);
        text-decoration: none;
 
        padding-bottom: 0.78rem;

        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }

    }

    .publication-main-title-p {
      // display: block;
      font-size: 1.3rem;
      font-weight: bold;
      color: #edbc48;
      text-shadow: .1px .1px .2px rgba(0, 0, 0, 1);
      text-decoration: none;

      padding-bottom: 0.78rem;

      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }

  }

    .publication-main-title:hover {
        text-decoration: underline;
      }

    .publication-main-authors {

        // margin-top: 1rem;
        font-size: 1.1rem;
        color: var(--primary-color);
        text-shadow: .1px .1px .2px rgba(0, 0, 0, 1);
        padding-bottom: 0.28rem;

        @media screen and (max-width: 768px) {
          font-size: .8rem;
        }
    }

}

.publication-main-info {
  display: flex; /* Use flexbox to align items in a row */
  // align-items: center; /* Center items vertically */
  justify-content: center;
  text-decoration: none;
  color: white;
  // margin-top: 1rem;
  @media screen and (max-width: 768px) {
    font-size: .8rem;
  }
}

.publication-main-info a {

  text-decoration: none;
  color: #edbc48;
  // margin-top: 1rem;
}


.publication-main-info a:hover {
  text-decoration: underline;
}

.publication-main-comment {
  color: white;
  text-decoration: none;

  @media screen and (max-width: 768px) {
    font-size: .8rem;
  }
}
.publication-main-comment:hover {
  text-decoration: underline;

}

.publication-main.even {
    background-color: #539dc5;
  }
  
  .publication-main.odd {
    background-color:  #0577b4;
  }




.papers-body-masters {

    // flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
    // background-color: red;
    // padding: 2rem;

    h2 {

        font-size: 2.4rem;
        font-weight: bold;
        color: #f99100;
        text-shadow: .5px .5px 1px rgba(0, 0, 0, 1);
        margin-bottom: 1rem;
    }

}


.masters-main {
    padding: 1rem;
    flex-direction: column;
    text-align: center;
    width: 94%;
    
    
  }
  


  .papers-main-info {
    display: flex; /* Use flexbox to align items in a row */
    // align-items: center; /* Center items vertically */
    justify-content: center;
    text-decoration: none;
    color: #edbc48;
    // margin-top: 1rem;
    @media screen and (max-width: 768px) {
      font-size: .8rem;
    }
  }
  
  .papers-main-info a {
  
    text-decoration: none;
    color: white;
    // margin-top: 1rem;
  }
  
  
  .papers-main-info a:hover {
    text-decoration: underline;
  }

  .masters-main.even {
    background-color: #539dc5;
  }
  
  .masters-main.odd {
    background-color: #0577b4;
  }
  
  

.masters-main h3 {

    // display: block;
    font-size: 1.3rem;
    font-weight: bold;
    color: #edbc48;
    text-shadow: .1px .1px .2px rgba(0, 0, 0, 1);
    text-decoration: none;

    margin-bottom: 0.2rem;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }


}




#papers .ood-message {

    font-size: 0.85rem;
    font-weight: bold;
    color: black;
    text-shadow: none;
    margin-top: 0.7rem;

    @media screen and (max-width: 768px) {
      font-size: .7rem;
    }

}

