.app__footer {
    display: flex;
    // flex-wrap: wrap;
    flex-direction: row;
    // justify-content: space-between;
    // gap: 1;
    margin-top: 1rem;
    align-items: center;
    // background-color: red;

    width: 100%;
    // max-height: 68%;
  
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

.footer-image {

    display: flex;
    justify-content: center;
    // max-height: 60%;
    // background-color: green;
    // display: none;


    img {
        border-radius: 10%;
        //max-height: 70%;
        max-width: 80%;
        object-fit: cover;
    }
}



.app__footer-body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    // justify-content: space-between;
    // justify-content: center;
    align-items: center;
    // background-color: blue;
    width: 100%;
  
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  
  .app__footer-cards {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    // margin: 4em 2rem 2rem;
    // background-color: yellow;
  
    .app__footer-card {
      min-width: 290px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
  
      margin: 1rem 0;
      padding: 1rem;
      border-radius: 10px;
      cursor: pointer;
      background-color: #fef4f5;
  
      transition: all 0.3s ease-in-out;
  
      img {
        width: 40px;
        height: 40px;
        margin: 0 0.7rem;
      }
  
      p {
        font-weight: 500;
      }
      a {
        text-decoration: none;
        font-weight: 500;
      }
      &:hover {
        box-shadow: 0 0 25px #fef4f5;
      }
  
      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }
  
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  
  .app__footer-cards .app__footer-card:last-child {
    background-color: #f2f7fb;
  }
  
  .app__footer-cards .app__footer-card:last-child:hover {
    box-shadow: 0 0 25px #f2f7fb;
  }
  
  .app__footer-form {
    width: 80%;
    flex-direction: column;
    margin: 1rem 2rem;
    // background-color: yellow;
  
    div {
      width: 100%;
  
      margin: 0.75rem 0;
      border-radius: 10px;
      cursor: pointer;
      background-color: var(--primary-color);
  
      transition: all 0.3s ease-in-out;
  
      input,
      textarea {
        width: 100%;
        padding: 0.95rem;
        border: none;
        border-radius: 7px;
        background-color: var(--primary-color);
  
        font-family: var(--font-base);
        color: var(--secondary-color);
        outline: none;
      }
  
      textarea {
        height: 110px;
      }
  
      &:hover {
        box-shadow: 0 0 25px var(--primary-color);
      }
    }
  
    button {
      padding: 1rem 2rem;
      border-radius: 10px;
      border: none;
      background-color: var(--secondary-color);
  
      font-weight: 500;
      color: var(--white-color);
      outline: none;
      margin: .8rem 0 0 0;
      font-family: var(--font-base);
  
      transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
      cursor: pointer;
  
      &:hover {
        background-color: #2430af;
      }
    }
  
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 1rem 0;
    }
  }
  
.footer-submit {

  display: flex;
  flex-direction: column;
  align-items: center;
  // max-height: 10%;
  justify-content: space-between;
  // margin-top: 1rem;
  //height: 80%;
  // background-color: green;

    .footer-submit-text {
        padding-bottom: 1rem;
        font-size: 2rem;
        font-weight: bold;
        color: var(--secondary-color);
        text-shadow: .1px .1px .2px rgba(0, 0, 0, 1);
        margin-bottom: 0rem;
        text-align: center;

        @media screen and (max-width: 768px) {
        font-size: 1.3rem;
      }
    }


    .footer-submit-image {
      // background-color: red;
      height: 120%;
      display: flex;
      justify-content: center;
      

      img {

               // width: 50%;
        // max-height: 30%;
        object-fit: cover;
        // margin-bottom: -5rem;
        max-width: 38%;
        border-radius: 50%;

        @media screen and (max-width: 768px) {
          max-width: 68%;
        }
        
      }


 
    }

    }


    .footer-head-text {
      font-size: 2.4rem;
      font-weight: bold;
      color: var(--secondary-color);
      text-shadow: .5px .5px 1px rgba(0, 0, 0, 1);
      margin-bottom: 1rem;
      text-align: center;

      
  

  }